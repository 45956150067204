import { useUser } from "../../contexts/user";
import GenericButton from "../GenericButton";

import "./styles.scss";

const ToSModal = () => {
  const user = useUser();

  const onAccept = () => {
    user.acceptTOS();
  };

  const onLogout = () => {
    user.logout();
  };

  if (!user.user || user.user?.hasAcceptedTOS) return null;

  return (
    <div className="tos-modal-container">
      <div className="tos-modal">
        <div className="header">New Terms of Service and Privacy Policy</div>
        <div className="content">
          <a href="/TermsandCondition.pdf" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/PrivacyPolicy.pdf" target="_blank">
            Privacy Policy
          </a>{" "}
          have been updated.
          <br />
          You need to accept them to continue using the site.
        </div>
        <div className="row">
          <GenericButton
            label="Logout"
            onClick={onLogout}
            withGlow
            style={{
              flex: 1,
              marginRight: "20px",
            }}
          />
          <GenericButton
            label="I accept"
            onClick={onAccept}
            style={{
              flex: 1,
              marginLeft: "20px",
              backgroundColor: "#E72313",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ToSModal;
