import { useToasts } from "../../contexts/toasts";
import { Toast } from "../../types";

import "./styles.scss";

const ToastItem = ({ toast }: { toast: Toast }) => (
  <div className={`toast ${toast.type}`}>
    <div className="content">{toast.message}</div>
  </div>
);

const ToastsRenderer = () => {
  const { toasts } = useToasts();

  return (
    <div className="toasts-container">
      {toasts.slice(-5).map((toast) => (
        <ToastItem key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

export default ToastsRenderer;
