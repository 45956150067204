import { useNavigate, useParams } from "react-router-dom";
import { useGuild } from "../../contexts/guild";
import { useUser } from "../../contexts/user";
import { usePlatforms } from "../../contexts/platforms";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import SectionHeader from "../../components/SectionHeader";
import LabeledInput from "../../components/LabeledInput";
import GenericButton from "../../components/GenericButton";

import AddIcon from "../../assets/addCircle.svg";
import RemoveIcon from "../../assets/removeCircle.svg";

import "./styles.scss";

const GuildApplySection = () => {
  const guild = useGuild();
  const params = useParams<{ id: string }>();
  const user = useUser();
  const platformsContext = usePlatforms();

  const [application, setApplication] = useState("");
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      guild.loadGuild(params.id);
    }
    console.log("user in apply", user.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const onSend = async () => {
    setLoading(true);
    await guild.apply(platforms, application);
    await user.reloadUser();
    setLoading(false);
    navigate(`/guilds/${params.id}`);
  };

  if (guild.loading) {
    return <Loader fill />;
  }

  return (
    <div className="guild-apply-section">
      <SectionHeader
        title={`Applying to ${guild.guild?.name || "(unknown)"}`}
        centered
      />
      <div className="section">
        <div className="platforms-container">
          <div className="label">Platforms:</div>
          <div className="platforms">
            {platformsContext.platforms.map((p) => {
              const enabled = platforms.includes(p.id);
              return (
                <div
                  className={`platform ${enabled ? "enabled" : "disabled"}`}
                  onClick={() => {
                    if (enabled) {
                      setPlatforms(platforms.filter((id) => id !== p.id));
                    } else {
                      setPlatforms([...platforms, p.id]);
                    }
                  }}
                >
                  <img src={p.icon} alt={p.name} className="platform-icon" />
                  {enabled ? (
                    <img src={RemoveIcon} alt="Remove" className="icon" />
                  ) : (
                    <img src={AddIcon} alt="Add" className="icon" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <LabeledInput
          label="Your application:"
          value={application}
          onChange={setApplication}
          placeholder={`Let other members of ${
            guild.guild?.name || "(unknown)"
          } know why you want to join!`}
          multiline
          inputStyle={{ height: "283px" }}
        />
        <div className="right">
          <GenericButton
            label="Send"
            withGlow
            visibleDisabled
            disabled={platforms.length === 0 || application.length === 0}
            onClick={onSend}
          />
        </div>
      </div>
    </div>
  );
};

export default GuildApplySection;
