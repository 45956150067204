import SectionHeader from "../../components/SectionHeader";

import "./styles.scss";

const PromoteYourGuildSection = () => {
  return (
    <div className="pyg-section">
      <SectionHeader title="How to Promote Your Guild on Diablo4Guild.com" />
      <div className="container">
        <div className="section">
          If you're a fan of Diablo 4, you probably know how important it is to
          have a good guild to play with. A guild can provide you with support,
          friendship, loot, and fun. But how do you find the right guild for
          you? And how do you make your guild stand out from the rest?
        </div>
        <div className="section">
          That's where Diablo4Guild.com comes in. Diablo4Guild.com is a website
          dedicated to Diablo 4 guilds and clans. It allows you to search for
          and filter by platforms and language. You can also create your own
          clan page, add your guild name, guild tag, official Discord,
          description, guild requirements, guild logo, platforms (PC, Xbox,
          PlayStation), and invite others to join.
        </div>
        <div className="section">
          But creating a clan page is not enough. You also need to promote your
          guild and attract new members. Here are some tips on how to do that:
        </div>
        <div className="section">
          <strong>Be active and friendly.</strong> The best way to promote your
          guild is to be active and friendly in the game and on the website.
          Participate in events, dungeons, raids, PvP, and chat with other
          players. Show them that your guild is fun and welcoming.
        </div>
        <div className="section">
          <strong>Use social media.</strong> Another way to promote your guild
          is to use social media platforms like Twitter, Facebook, Instagram,
          YouTube, Twitch, etc. Share your guild's achievements, screenshots,
          videos, memes, tips, and news. Use hashtags like #Diablo4Guilds or
          #Diablo4Clans to reach more potential members.
        </div>
        <div className="section">
          <strong>Be unique and creative.</strong> Finally, try to be unique and
          creative when promoting your guild. Think of a catchy name, logo,
          slogan, or theme for your guild. Make your clan page attractive and
          informative. Offer incentives or rewards for joining or staying in
          your guild. Host contests or giveaways for your members or followers.
        </div>
        <div className="section">
          Following these tips can make promoting your guild on Diablo4Guild.com
          easy and fun. Remember that the key is to be active, friendly, social,
          and creative. With a little effort and time, you can grow your guild
          and enjoy Diablo 4 even more!
        </div>
      </div>
    </div>
  );
};

export default PromoteYourGuildSection;
