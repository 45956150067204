export const dateToCountdown = (
  date: string | Date,
  startDate: Date,
  secondsElapsed: number
): string => {
  const realDate = new Date(date);
  const diff = realDate.getTime() - startDate.getTime() - secondsElapsed * 1000;
  if (diff < 0) {
    return "00:00:00";
  }

  const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");
  const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(
    2,
    "0"
  );
  const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const numberToOrder = (num: number): string => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;
  if (lastTwoDigits >= 10 && lastTwoDigits <= 20) {
    return `${num}th`;
  }
  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
