import { useState } from "react";
import SectionHeader from "../../components/SectionHeader";

import "./styles.scss";

const HowToLevelSection = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="htl-section">
      <SectionHeader title="How to level in Diablo 4 - tips on starting the adventure" />
      <div className="container">
        <div className="section">
          We have prepared for you a list of tips that will come in handy (as
          they may not be so obvious for some players) if you care about fast
          leveling. There will be something for both those players who want to
          see the end credits as soon as possible and those who plan to hit
          level 50 in the first act.
        </div>
        <div className="section">
          1. If you don't care about challenging gameplay, definitely consider
          starting with World Tier 1.The only profits World Tier 2 gives you are
          20% more experience and gold gained from mobs. Items that drop are the
          same and experience from quests is equal on both tiers. So WT2 is not
          profitable if you plan to gear yourself with legendary items and
          unlock World Tier 3 as quickly as possible.
        </div>
        <div className="section">
          2. Find all Altars of Lilith. They will provide you with bonuses that
          will be shared among all characters on your account. If you want to
          know their location (or all the other activities on the map), check
          out the Interactive Map for Diablo 4:{" "}
          <a href="https://diablo4map.com/" target="_blank" rel="noreferrer">
            Diablo4Map.com
          </a>
        </div>
        <div className="section">
          3. Always use elixirs! In addition to the bonuses they bring with
          them, they also provide 5% more experience for 30 minutes. Crafting
          them requires basic materials so you will always be able to create
          some kind of potion. All in all, there is no reason not to take
          advantage of it.
        </div>
        <div className="section">
          4. If you see an event going on in the open world nearby it is worth
          going there. Even at the very end of it, you will get rewards from it.
        </div>
        <div className="section">
          5. Play with other players! The level scaling system in Diablo 4 is
          very well implemented, so regardless of your level or story progress,
          you can always join a friend and have the same challenge. Playing in a
          party will grant you 10% more experience from everything. In addition,
          if you are next to a player who is not in your party you both get 5%
          more experience, so it's worth doing some open world events with other
          players. If you don't yet have a team to play with or want to join a
          clan, you can check the guild list here:{" "}
          <a href="https://diablo4guild.com/" target="_blank" rel="noreferrer">
            Diablo4Guild.com
          </a>
        </div>
        <div className="section">
          6. If you clear the entire map of every activity first then don't
          forget to claim each of the five rewards for that region under the
          "Region Progress" tab.
        </div>
        <div className="section">
          7. (Minor spoiler!){" "}
          <span
            className={`hidden ${show && "show"}`}
            onClick={() => setShow(true)}
          >
            If you want to quickly unlock a horse to move more efficiently
            around the map you must reach Act 4 - then it will be unlocked for
            each character on your account.
          </span>
        </div>
        <div className="section">
          8. Remember to play in your own way. For some, Diablo 4 is a race to
          the endgame, while for others it's a delight to explore Sanctuary at
          your own pace. Our advice is universal and you can apply only some of
          it. Take your time. 🙂
        </div>
      </div>
    </div>
  );
};

export default HowToLevelSection;
