import "./styles.scss";

const Footer = () => (
  <footer>
    <div className="legal">
      <a href="/PrivacyPolicy.pdf" target="_blank">
        Privacy Policy
      </a>
      <a href="/TermsandCondition.pdf" target="_blank">
        Terms of Service
      </a>
    </div>
    <div className="info">
      Diablo4Map.com and Diablo4Guild.com are your ultimate sources for
      everything related to Diablo 4, the action role-playing game from Blizzard
      Entertainment. Whether you want to explore the vast open world of
      Sanctuary, join or create a guild with other players, or search for hidden
      items, collectibles, secrets, world bosses, Altars of Lilith locations,
      Diablo 4 Helltide events, and easter eggs in the game, we have you
      covered. Our websites feature interactive map, guilds, clans, and more to
      help you get ready for the epic battle against the forces of Hell. Join
      our community today and share your passion for Diablo 4 with us!
    </div>
  </footer>
);

export default Footer;
