import { createContext, useContext, useEffect, useState } from "react";
import api from "../api";

type ContextType = {
  languages: string[];
  loadLanguages: () => Promise<void>;
};

export const LanguagesContext = createContext<ContextType>({
  languages: [],
  loadLanguages: async () => {},
});

export const LanguagesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    loadLanguages();
  }, []);

  const loadLanguages = async () => {
    const response = await api.languages.getAll();
    setLanguages(response);
  };

  return (
    <LanguagesContext.Provider value={{ languages, loadLanguages }}>
      {children}
    </LanguagesContext.Provider>
  );
};

export const useLanguages = () => {
  const context = useContext(LanguagesContext);
  if (context === undefined) {
    throw new Error("useLanguages must be used within a LanguagesProvider");
  }
  return context;
};
