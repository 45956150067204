import {
  Guild,
  GuildApplication,
  GuildCreateProps,
  GuildUpdateProps,
  LoginProps,
  Platform,
  RegisterProps,
  User,
} from "../types";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api-dev.diablo4guild.com/diablo4";

const apiHandler = (
  url: string,
  method = "GET",
  body?: any,
  withHeader = false
) => {
  const options: RequestInit = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  let data: any = null;
  let status = 0;
  return fetch(url, options)
    .then((res) => {
      data = res;
      status = res.status;

      return res
        .json()
        .then((data) => {
          if (withHeader) {
            return { headers: res.headers, data: data, status };
          }
          return data;
        })
        .catch((e) => {
          console.log("API error (1):", e);
          return data && data.status < 300;
        });
    })
    .catch((e) => {
      console.log("API error (2):", e);
      console.log(e.toString());
      return { success: false, status, message: e.toString() };
    });
};

const api = async (
  url: string,
  method = "GET",
  body?: any,
  withHeaders = false
) => apiHandler(`${apiUrl}${url}`, method, body, withHeaders);

const routes = {
  guilds: {
    get: (id: number, withIcon?: boolean): Promise<Guild> =>
      api(`/guilds/${id}${withIcon ? "?icon=1" : ""}`),
    getSlug: (slug: string, withIcon?: boolean): Promise<Guild> =>
      api(`/guilds/urlslug/${slug}${withIcon ? "?icon=1" : ""}`),
    getAll: ({
      page,
      perPage,
      filters,
    }: {
      page?: number;
      perPage?: number;
      filters?: {
        platform?: string;
        language?: string;
      };
    }): Promise<{ data: Guild[]; headers: Headers }> =>
      api(
        `/guilds?page=${page || 1}&perPage=${
          perPage || 10
        }&sort=["votes","DESC","id","ASC"]${
          filters?.language ? `&language=${filters.language}` : ""
        }${filters?.platform ? `&platforms=["${filters.platform}"]` : ""}`,
        "GET",
        undefined,
        true
      ),
    add: (guild: GuildCreateProps): Promise<Guild> =>
      api("/guilds", "POST", guild),
    update: (id: number, guild: GuildUpdateProps): Promise<Guild> =>
      api(`/guilds/${id}`, "PATCH", guild),
    delete: (id: number): Promise<Guild> => api(`/guilds/${id}`, "DELETE"),
    getIcon: (id: number) => `${apiUrl}/guilds/${id}/icon`, // img src
    vote: (id: number): Promise<{ success: boolean }> =>
      api(`/guilds/${id}/vote`, "POST"),
    leave: (id: number): Promise<{ success: boolean }> =>
      api(`/guilds/${id}/leave`, "POST"),
  },
  applications: {
    getAll: (id: number): Promise<GuildApplication[]> =>
      api(`/guilds/${id}/applications`),
    apply: (
      id: number,
      application: Partial<GuildApplication>
    ): Promise<GuildApplication> =>
      api(`/guilds/${id}/applications`, "POST", application),
    dismiss: (id: number, applicationId: number) =>
      api(`/guilds/${id}/applications/${applicationId}`, "DELETE"),
    accept: (id: number, applicationId: number) =>
      api(`/guilds/${id}/applications/${applicationId}`, "POST"),
  },
  members: {
    getAll: (id: number) => api(`/guilds/${id}/members`),
    delete: (id: number, memberId: number) =>
      api(`/guilds/${id}/members/${memberId}`, "DELETE"),
    changeRole: (id: number, memberId: number, role: string) =>
      api(`/guilds/${id}/members/${memberId}`, "POST", { role }),
    getAvatar: (id: number) => `${apiUrl}/avatar/${id}`, // img src
  },
  profile: {
    get: (id: number) => api(`/profile/${id}`),
  },
  languages: {
    getAll: (): Promise<string[]> => api("/languages"),
  },
  platforms: {
    getAll: (): Promise<Platform[]> => api("/platforms"),
  },
  user: {
    update: (id: number, user: Partial<User>) =>
      api(`/users/${id}`, "PATCH", user),
    getMyself: () => api("/auth"),
    register: (props: RegisterProps) => api("/auth/register", "POST", props),
    login: (props: LoginProps) => api("/auth", "POST", props),
    logout: () => api("/auth", "DELETE"),
    deleteAccount: (id: number) => api(`/users/${id}`, "DELETE"),
  },
  tos: {
    accept: () => api("/tos", "POST"),
  },
};

export default routes;
