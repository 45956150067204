import MyGuildIcon from "../../assets/MyGuild.svg";
import BuildsIcon from "../../assets/btnBuilds.svg";
import MapsIcon from "../../assets/btnMaps.svg";
import SectionHeader from "../../components/SectionHeader";

import "./styles.scss";

const FAQSection = () => {
  return (
    <div className="faq-section">
      <SectionHeader title="Frequently Asked Questions" />
      <div className="item">
        <div className="question">Q: What is Diablo4Guild.com?</div>
        <div className="answer">
          A: Diablo4Guild.com is a website dedicated to Diablo 4 guilds and
          clans. It allows you to search for and filter by names and platforms.
          You can also create your own clan page, add your guild name, guild
          tag, official Discord, description, guild requirements, guild logo,
          platforms (PC, Xbox, PlayStation), and invite others to join.
        </div>
      </div>
      <div className="item">
        <div className="question">Q: How do I create a clan page?</div>
        <div className="answer">
          A: To create a clan page, you need to register an account on
          Diablo4Guild.com. Then, click on the "Add Guild" button on the top
          right corner of the website. Fill in the required fields and upload
          your guild logo. You can also add optional information such as your
          guild's Discord link. Once you're done, click on the "Save" button and
          your clan page will be live.
        </div>
      </div>
      <div className="item">
        <div className="question">
          Q: How do I edit or delete my clan page?{" "}
        </div>
        <div className="answer">
          A: To edit or delete your clan page, you need to log into your account
          on Diablo4Guild.com. Then, go to your clan page by clicking My guild
          and click on the "Manage". You can make changes or delete your guild
          as needed.
        </div>
      </div>
      <div className="item">
        <div className="question">Q: How do I join a guild or clan?</div>
        <div className="answer">
          A: To join a guild or clan, you need to browse the website and find a
          guild or clan that suits your preferences. You can use the filters on
          the left side of the website to narrow down your search platform and
          language. Once you find a guild or clan that interests you, click on
          their name or logo to go to their clan page. There, you can see more
          information about them and apply to the guild. Once approved - you
          will be the visible member of the guild.
        </div>
      </div>
      <div className="item">
        <div className="question">Q: How do I vote for a guild or clan?</div>
        <div className="answer">
          A: To vote for a guild or clan, you need to go to their clan page and
          click on the "Vote" button on the top right corner of the page. You
          can also vote directly on the main page. You can only vote once per
          day for each guild or clan. Voting helps increase the visibility and
          ranking of your favorite guilds and clans on the website.
        </div>
      </div>
      <div className="item">
        <div className="question">
          Q: How do I report a problem or give feedback?
        </div>
        <div className="answer">
          A: To report a problem or give feedback, you can use the "Contact Us"
          link at the bottom of the website. We appreciate your input and will
          try to respond as soon as possible.
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
