import { useEffect, useState } from "react";
import GenericButton from "../../components/GenericButton";

import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils";
import Checkbox from "../../components/Checkbox";
import { useUser } from "../../contexts/user";
import { useGuilds } from "../../contexts/guilds";

const LoginRegisterSection = () => {
  const [variant, setVariant] = useState<"login" | "register">("login");
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [emailValid, setEmailValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const user = useUser();
  const navigate = useNavigate();
  const guilds = useGuilds();

  useEffect(() => {
    if (location.pathname === "/login") {
      setVariant("login");
    } else if (location.pathname === "/register") {
      setVariant("register");
    }
  }, [location.pathname]);

  const switchVariant = () => {
    user.clearErrors();
    setVariant(variant === "login" ? "register" : "login");
  };

  const onSubmit = async () => {
    let success = false;
    if (variant === "login") {
      success = await user.login({ username, password });
    } else {
      success = await user.register({ username, email, password });
    }

    if (success) {
      guilds.loadGuilds(1, true);
      navigate("/");
    }
  };

  const updateValue = (
    value: string,
    type: "username" | "password" | "email"
  ) => {
    switch (type) {
      case "username":
        setUsername(value);
        setUsernameValid(value.length >= 3);
        break;
      case "password":
        setPassword(value);
        setPasswordValid(value.length >= 3);
        break;
      case "email":
        setEmail(value);
        setEmailValid(validateEmail(value));
        break;
    }
  };

  const isAllValid =
    variant === "login" ||
    (emailValid && usernameValid && passwordValid && acceptedTerms);

  const handlePasswordKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && isAllValid) {
      await onSubmit();
    }
  };

  return (
    <div className="login-register-section">
      <div className="inner-container">
        {variant === "register" && (
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => updateValue(e.target.value, "email")}
          />
        )}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => updateValue(e.target.value, "username")}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => updateValue(e.target.value, "password")}
          onKeyDown={handlePasswordKeyDown}
        />
        {variant === "register" && (
          <Checkbox
            label={
              <span>
                I accept the{" "}
                <a href="/TermsandCondition.pdf" target="_blank">
                  Terms&nbsp;of&nbsp;Service
                </a>{" "}
                and{" "}
                <a href="/PrivacyPolicy.pdf" target="_blank">
                  Privacy&nbsp;Policy
                </a>
              </span>
            }
            checked={acceptedTerms}
            onChange={(checked) => setAcceptedTerms(checked)}
          />
        )}
        <div className="errors">
          {user.errors.map((error) => (
            <div className="error" key={error}>
              {error}
            </div>
          ))}
        </div>
        <GenericButton
          withGlow
          disabled={!isAllValid || !username || !password}
          visibleDisabled
          label={variant === "login" ? "Login" : "Register"}
          style={{ marginTop: 20 }}
          loading={user.loading}
          onClick={onSubmit}
        />
        <div className="no-account">
          <span>
            {variant === "login"
              ? "Don't have an account?"
              : "Already have an account?"}
          </span>{" "}
          <span className="cta" onClick={switchVariant}>
            {variant === "login" ? "Register now!" : "Login now!"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterSection;
