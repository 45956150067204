import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LabeledInput from "../../components/LabeledInput";
import Loader from "../../components/Loader";
import SectionHeader from "../../components/SectionHeader";
import { useGuild } from "../../contexts/guild";
import { useUser } from "../../contexts/user";
// import { Buffer } from "buffer";

import GenericButton from "../../components/GenericButton";
import ImageUploadPreview from "../../components/ImageUploadPreview";
import { usePlatforms } from "../../contexts/platforms";
import "./styles.scss";

import AddIcon from "../../assets/addCircle.svg";
import RemoveIcon from "../../assets/removeCircle.svg";
import ApplicationListEntry from "../../components/ApplicationListEntry";
import DropdownSelect from "../../components/DropdownSelect";
import MemberListEntry from "../../components/MemberListEntry";
import RedBox from "../../components/RedBox";
import { useLanguages } from "../../contexts/languages";
import { useToasts } from "../../contexts/toasts";

// @ts-ignore
// window.Buffer = Buffer;

const GuildManagementSection = () => {
  const guild = useGuild();
  const user = useUser();
  const params = useParams<{ id?: string }>();
  const platformsContext = usePlatforms();
  const languages = useLanguages();

  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [language, setLanguage] = useState("");
  const [requirements, setRequirements] = useState("");
  const [socialMedia, setSocialMedia] = useState("");

  const [error, setError] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toasts = useToasts();

  const myRole =
    user.user?.membershipEnt?.find((ent) => ent.guild === guild.guild?.id)
      ?.role || "";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      guild
        .loadGuild(params.id, {
          withIcon: true,
          withApplications: true,
        })
        .then((newGuild) => {
          if (!newGuild) return;
          console.log("Loaded guild is:", newGuild);

          // const newIcon = newGuild.icon
          //   ? `data:image/png;base64,` +
          //     Buffer.from((newGuild.icon as any).data).toString("base64")
          //   : "";
          // console.log("newIcon", newIcon);

          setName(newGuild.name);
          setTag(newGuild.tag || "");
          setDescription(newGuild.description || "");
          setIcon(newGuild.icon || "");
          setPlatforms(newGuild.platforms as any);
          setLanguage(newGuild.language || "");
          setRequirements(newGuild.requirements || "");
          setSocialMedia(newGuild.discordLink || "");
        });
    } else {
      guild.unloadGuild();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  if (guild.loading) {
    return <Loader fill />;
  }

  const onSave = async () => {
    // TODO: Handle discord links better
    if (
      socialMedia.length > 0 &&
      !socialMedia.startsWith("https://discord.gg/") &&
      !socialMedia.startsWith("https://discord.com/invite/")
    ) {
      return;
    }

    setLoading(true);
    const result = await guild.createOrUpdateGuild({
      id: guild.guild?.id,
      name,
      tag,
      description,
      icon: icon || null,
      language,
      requirements,
      platforms,
      discordLink: socialMedia,
    });
    console.log("save result", result);
    setLoading(false);
    user.reloadUser();
    if (result?.urlSlug) {
      navigate(`/guilds/${result.urlSlug}`);
    } else if (guild.guild?.urlSlug) {
      navigate(`/guilds/${guild.guild.urlSlug}`);
    }
  };

  const onDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      guild.deleteGuild();
      navigate("/");
    }
  };

  return (
    <div className="guild-management-section">
      <SectionHeader
        title={
          guild.guild?.name ? `Manage ${guild.guild.name}` : `Create Your Guild`
        }
        centered
      />
      <div className="inner-container">
        <div className="section left">
          <div className="left-info">
            <ImageUploadPreview
              image={icon}
              onChange={(s, error) => {
                setIcon(s);
                if (error) {
                  setError(error);
                  toasts.add({
                    type: "error",
                    message: error,
                    sameId: "icon-error",
                  });
                } else {
                  setError("");
                }
              }}
              onRemove={() => {
                setIcon("");
                setError("");
              }}
            />
            <LabeledInput
              label="Guild Name:"
              value={name}
              onChange={setName}
              placeholder="Name of your guild"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <LabeledInput
            label="Guild Tag:"
            value={tag}
            onChange={setTag}
            placeholder="Short tag of your guild"
          />
          <LabeledInput
            label="Guild Description:"
            value={description}
            onChange={setDescription}
            placeholder="Description of your guild. The first two lines will be shown in the global guild list. Get creative!"
            multiline
            inputStyle={{ height: "236px" }}
          />
        </div>
        <div className="section right">
          <div className="platforms-container">
            <div className="label">Platforms:</div>
            <div className="platforms">
              {platformsContext.platforms.map((p) => {
                const enabled = platforms.includes(p.id);
                return (
                  <div
                    className={`platform ${enabled ? "enabled" : "disabled"}`}
                    onClick={() => {
                      if (enabled) {
                        setPlatforms(platforms.filter((id) => id !== p.id));
                      } else {
                        setPlatforms([...platforms, p.id]);
                      }
                    }}
                  >
                    <img src={p.icon} alt={p.name} className="platform-icon" />
                    {enabled ? (
                      <img src={RemoveIcon} alt="Remove" className="icon" />
                    ) : (
                      <img src={AddIcon} alt="Add" className="icon" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="language-container">
            <div className="label">Language:</div>
            <DropdownSelect
              options={languages.languages.map((l) => ({ label: l, value: l }))}
              onChange={(values) => setLanguage(values[0]?.value || "")}
              values={language ? [{ label: language, value: language }] : []}
              placeholder="The main language your guild speaks"
            />
          </div>
          <LabeledInput
            label="Guild Requirements:"
            value={requirements}
            onChange={setRequirements}
            placeholder="Requirements to join your guild"
            multiline
            inputStyle={{ height: "236px" }}
          />
        </div>
      </div>
      <LabeledInput
        label="Discord:"
        value={socialMedia}
        onChange={setSocialMedia}
        placeholder="Discord server link"
      />
      <div className="spaced">
        {myRole === "OWNER" ? (
          <GenericButton
            label={
              confirmDelete ? "Press again to confirm deletion" : "Delete Guild"
            }
            onClick={onDelete}
            disabled={!guild.guild}
            visibleDisabled
            withGlow={confirmDelete}
            style={{
              width: confirmDelete ? undefined : "175px",
              backgroundColor: "#E7231366",
            }}
          />
        ) : (
          <div />
        )}
        <GenericButton
          label="Save"
          onClick={onSave}
          visibleDisabled
          style={{ width: "175px", backgroundColor: "#E72313" }}
          loading={loading}
          whiteLoading
        />
      </div>
      {!!guild.guild && (
        <>
          <RedBox title="Guild Applications" centered narrower>
            {guild.applications
              .filter((a) => a.state === "AWAITING")
              .map((application) => (
                <ApplicationListEntry application={application} />
              ))}
          </RedBox>
          <div className="spaced" />
          <RedBox title="Guild Members" centered narrower>
            {guild.members.map((member) => (
              <MemberListEntry
                member={member}
                withOwnerActions={myRole === "OWNER"}
                withAdminActions={["OWNER", "ADMIN"].includes(myRole)}
              />
            ))}
          </RedBox>
        </>
      )}
    </div>
  );
};

export default GuildManagementSection;
