import "./styles.scss";

interface HeaderButtonProps {
  label: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const HeaderButton = ({
  label,
  icon,
  onClick,
  disabled,
  style,
}: HeaderButtonProps) => (
  <div
    className="header-btn"
    onClick={disabled ? undefined : onClick}
    style={style}
  >
    {icon && <img src={icon} alt={label} />}
    <span>{label}</span>
  </div>
);

export default HeaderButton;
