import {
  GuildApplication,
  GuildMember,
  UserGuildApplication,
} from "../../types";
import "./styles.scss";

import api from "../../api";
import { usePlatforms } from "../../contexts/platforms";
import GenericButton from "../GenericButton";
import { useGuild } from "../../contexts/guild";

interface ApplicationListEntryProps {
  application: GuildApplication;
}

const ApplicationListEntry = ({ application }: ApplicationListEntryProps) => {
  const platforms = usePlatforms();
  const guild = useGuild();

  const onAccept = async () => {
    await guild.resolveApplication(application.id, true);
  };

  const onReject = async () => {
    await guild.resolveApplication(application.id, false);
  };

  return (
    <div className="application-list-entry">
      <div className="member-list-entry-inner">
        <div className="application-list-entry-innerer">
          <img
            src={api.members.getAvatar(application.userId)}
            alt="application"
            className="member-icon"
          />
          <div className="info-box">
            <div className="member-name">{application.username}</div>
            <div className="member-info">
              {application.platforms?.map((platform) => (
                <img
                  src={platforms.platforms.find((p) => p.id === platform)?.icon}
                  className="platform"
                  key={platform}
                  alt={platform}
                />
              ))}
            </div>
          </div>
          <div className="actions">
            <GenericButton
              label="Approve"
              style={{ backgroundColor: "#57E71366", marginRight: "20px" }}
              rounder
              onClick={onAccept}
            />
            <GenericButton
              label="Reject"
              style={{ backgroundColor: "#E7231366" }}
              rounder
              onClick={onReject}
            />
          </div>
        </div>
        <div className="application-list-entry-innerer">
          <div className="description">
            {application.description.replace(/\n+/g, "\n")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationListEntry;
