import { useNavigate } from "react-router-dom";
import MyGuildIcon from "../../assets/MyGuild.svg";
import BuildsIcon from "../../assets/btnBuilds.svg";
import MapsIcon from "../../assets/btnMaps.svg";

import "./styles.scss";

const ProductsSection = () => {
  const navigate = useNavigate();

  return (
    <div className="products-section">
      <div className="container">
        <div
          className="product"
          onClick={() => (window.location.href = "https://diablo4map.com/")}
        >
          <img src={MapsIcon} alt="Maps" />
          <div className="product-name">Maps</div>
        </div>
        <div className="product soon">
          <img src={BuildsIcon} alt="Builds" />
          <div className="product-name">Builds</div>
          <div className="product-soon">(soon!)</div>
        </div>
        <div className="product" onClick={() => navigate("/")}>
          <img src={MyGuildIcon} alt="Guilds" />
          <div className="product-name">Guilds</div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSection;
