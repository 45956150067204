import { createContext, useContext, useEffect, useState } from "react";
import api from "../api";
import { Platform } from "../types";

import PlayStationIcon from "../assets/platforms/playstation.svg";
import XboxIcon from "../assets/platforms/xbox.svg";
import WindowsIcon from "../assets/platforms/windows.svg"; // yuck

type ContextType = {
  platforms: Platform[];
  loadPlatforms: () => Promise<void>;
};

export const PlatformsContext = createContext<ContextType>({
  platforms: [],
  loadPlatforms: async () => {},
});

export const PlatformsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);

  useEffect(() => {
    loadPlatforms();
  }, []);

  const loadPlatforms = async () => {
    const response = await api.platforms.getAll();
    setPlatforms(
      response.map((p) => {
        switch (p.id) {
          case "PS":
            return { ...p, icon: PlayStationIcon };
          case "XBOX":
            return { ...p, icon: XboxIcon };
          case "PC":
            return { ...p, icon: WindowsIcon };
          default:
            return p;
        }
      })
    );
  };

  return (
    <PlatformsContext.Provider value={{ platforms, loadPlatforms }}>
      {children}
    </PlatformsContext.Provider>
  );
};

export const usePlatforms = () => {
  const context = useContext(PlatformsContext);
  if (context === undefined) {
    throw new Error("usePlatforms must be used within a PlatformsProvider");
  }
  return context;
};
